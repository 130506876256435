import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IAccountDetails} from "app/models";
import {ClientService} from "app/services/client/client.service";
import {first} from "rxjs/operators";
import {IOwnedVenue, IWidgetTheme, themeTypes, wrapperStyleType} from "shared-types/index";
import AppThemeService, {DEFAULT_FONT} from "app/services/theme/appTheme.service";
import LocationService from "shared-services/location-service";
import {PaymentApiRequests} from "shared-services/payment-service/paymentApiRequests";

// Define a type for the slice state
interface AppInitSlice {
    theme: IWidgetTheme,
    status: "loading" | "idle"
    error: string | null;
    accountId: string;
    account: IAccountDetails;
    globalSessionCorrelationId: string;
    activeVenue: IOwnedVenue;
    isPaymentSet: boolean;
    wrapperStyle: wrapperStyleType;
}

// Define the initial state using that type
const initialState: AppInitSlice = {
    theme: {
        palette: AppThemeService.getThemeDefaultPalette(),
        typography: AppThemeService.getThemeDefaultTypography(),
        defaultColors: true,
        defaultFont: DEFAULT_FONT,
        type: themeTypes.light
    },
    status: 'idle',
    error: null,
    accountId: '',
    account: {} as IAccountDetails,
    globalSessionCorrelationId: LocationService.uuidv4(),
    activeVenue: {} as IOwnedVenue,
    isPaymentSet: false,
    wrapperStyle: wrapperStyleType.standard
}


PaymentApiRequests.setUpRequestInterceptor('abc-widget', initialState.globalSessionCorrelationId);

export const GetAccount = createAsyncThunk(
    'test/getAccount',
    async (arg: string, {dispatch, getState}) => {

        dispatch(setLoader('loading'));
        return ClientService.getAccount(arg).pipe(
            first()
        ).toPromise().then(data => ({...data}));
    }
)

export const appInitSlice = createSlice({
    name: 'groupWidgetInit',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setDefaultTheme: (state, action: PayloadAction<IWidgetTheme>) => {
            return {
                ...state,
                theme: {
                    ...action.payload
                },
            }
        },
        setLoader: (state, {payload}: PayloadAction<"loading" | "idle">) => {
            state.status = payload;
        },
        setAccountId: (state, {payload}: PayloadAction<string>) => {
            state.accountId = payload;
        },
        setActiveVenue: (state, {payload}: PayloadAction<IOwnedVenue>) => {
            state.activeVenue = payload;
            state.isPaymentSet = !!payload.paymentSettings.paymentProvider;
        },
        setWrapperStyle: (state, {payload}: PayloadAction<wrapperStyleType>) => {
            state.wrapperStyle = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(GetAccount.pending, (state) => {
            // At that moment,
            // we change status to `loading`
            // and clear all the previous errors:
            state.status = 'loading';
        });

        // When a server responses with the data,
        // `GetSchedule.fulfilled` is fired:
        builder.addCase(GetAccount.fulfilled,
            (state, action: PayloadAction<any>) => {
                // We add the schedule into the state
                // and change `status` back to `idle`:
                state.account = action.payload;
                state.status = "idle";
            });

        // When a server responses with an error:
        builder.addCase(GetAccount.rejected,
            (state, {payload}) => {
                // We show the error message
                // and change `status` back to `idle` again.
                if (payload) {
                    state.error = 'Error';
                }
                state.status = "idle";
            });
    },
})

export const {setLoader, setAccountId, setDefaultTheme, setActiveVenue, setWrapperStyle} = appInitSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default appInitSlice.reducer